<!--
 * @Author: jiang
 * @Date: 2021-06-11 16:12:55
 * @Description: 人员列表
-->
<template>
  <div class="user-container g-page-list">
    <!-- 侧边栏 -->
    <el-scrollbar
      y
      class="g-bg g-mr-10"
      style=" width: 260px;"
    >
      <dept-tree
        ref="tree"
        manager
        @change="onCategoryChange"
      ></dept-tree>
    </el-scrollbar>

    <!-- 主内容区 -->
    <el-scrollbar
      y
      class="g-bg g-flex-fill"
    >

      <div class="g-p-20">
        <el-form
          ref="form"
          :model="searchForm"
          inline
          label-position="top"
        >
          <el-form-item
            label="用户姓名"
            prop="name"
          >
            <el-input
              v-model="searchForm.name"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="phone"
          >
            <el-input
              v-model="searchForm.phone"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item style="vertical-align: bottom;">
            <c-btn-search
              @search="refresh"
              @reset="onReset"
              @create="onAdd"
            ></c-btn-search>
          </el-form-item>
        </el-form>
        <el-table :data="list">
          <el-table-column
            label="ID"
            prop="id"
            width="80"
          />
          <el-table-column
            label="姓名"
            prop="name"
          />
          <el-table-column
            label="部门"
            prop="department.name"
          />
          <el-table-column
            label="职务"
            prop="duties"
          />
          <el-table-column
            label="手机号"
            prop="phone"
          />
          <!-- <el-table-column
            label="邮箱"
            prop="executive_end_data"
            width="140"
          /> -->
          <el-table-column
            label="操作"
            width="160"
            align="center"
          >
            <template slot-scope="{ row }">
              <c-btn-table
                :show="['info', 'update']"
                @info="onView(row)"
                @update="onEdit(row)"
              ></c-btn-table>
            </template>
          </el-table-column>
        </el-table>

        <b-pagination
          :page="page"
          @change="fetchList"
        ></b-pagination>
      </div>
    </el-scrollbar>

    <el-dialog
      title="用户信息"
      :visible.sync="visible"
    >
      <info-view
        v-if="formData.department_name"
        :data="formData"
      ></info-view>
      <detail-view
        v-else
        :data="formData"
        :loading="submitLoading"
        @submit="onSubmit"
      ></detail-view>
    </el-dialog>
  </div>
</template>

<script>
import { getUserPage, createUser, updateUser } from '@/api/user'
import DeptTree from '@/components/tree/dept'
import DetailView from './detail'
import InfoView from './info'

export default {
  components: {
    DeptTree,
    DetailView,
    InfoView,
  },
  data() {
    return {
      currentCatelog: null,
      list: [],
      searchForm: {
        name: '',
        phone: '',
      },
      page: {
        total: 0,
        current: 1,
        size: 15,
      },
      visible: false,
      formData: {},
      submitLoading: false,
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const params = {
        id: this.currentCatelog,
        page: this.page.current,
      }
      getUserPage(params).then(res => {
        res.data.forEach(item => {
          item.duties = item.duties ?? '-'
        })
        this.list = res.data
        this.page.total = res.total
      })
    },
    refresh() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.refresh()
    },
    onCategoryChange(id) {
      this.currentCatelog = id
      this.fetchList()
    },
    onAdd() {
      this.visible = true
      this.formData = {
        name: '',
        department_id: '',
        duties_id: '',
        phone: '',
        password: '',
        password_confirmation: '',
      }
    },
    onEdit(row) {
      this.visible = true
      this.formData = {
        id: row.id,
        name: row.name,
        department_id: row.department_id,
        duties_id: row.duties_id,
        phone: row.phone,
        password: '',
        password_confirmation: '',
      }
    },
    onView(row) {
      this.visible = true
      this.formData = {
        id: row.id,
        name: row.name,
        department_name: row.department.name,
        duties: row.duties,
        phone: row.phone,
      }
    },
    onSubmit(data) {
      this.submitLoading = true
      const id = data.id
      if (id) {
        updateUser(id, data)
          .then(res => {
            this.$message.success('修改成功')
            this.refresh()
          })
          .finally(() => {
            this.submitLoading = false
            this.visible = false
          })
      } else {
        createUser(data)
          .then(res => {
            this.$message.success('添加成功')
            this.refresh()
          })
          .finally(() => {
            this.submitLoading = false
            this.visible = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-container {
  display: flex;
  height: 100%;
  padding: 0 15px 15px;
}
</style>
